.nextArrow {
    right: -50px;
}

.prevArrow {
    left: -50px;
}

.nextArrow,
.prevArrow {
    top: 40%;
}

@media screen and (max-width: 768px) {
.nextArrow,
    .prevArrow {
        display: none !important;
    }
    
}