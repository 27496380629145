.PropertyPickerWidgetCarouselWrapper {
     padding-top: 20px;
}

.nextArrow {
    right: -50px;
}

.prevArrow {
    left: -50px;
}

.nextArrow,
.prevArrow {
    top: 40%;
}