// CookiePolicyMessage.scss

@import "custom.scss";

.allow-button {
  background-color: $primary;
  &:hover {
    background-color: darken($primary, 10%);
  }
}

.alert-cookie{
    background-color: #fff;
    padding: 15rem;
    box-shadow: 0px 0px 18px -6px rgba(0, 0, 0, 0.58);
}