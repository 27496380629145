.PropertyPoint {
    background-color: #fff;
    display: inline-block;
    padding: 5px 5px;
    cursor: pointer;
    text-align: center;
    border-radius: 100px;
    border: 1px solid #000;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
}

.PropertyPoint:hover{
    z-index: 99999999;
    background-color: #000;
    color: #fff;
}