.text-shadow{
   text-shadow: 1px 1px 2px black;
}

.property-section{
   padding-top: 1.5rem;
   padding-bottom: 3rem;
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
   border-bottom: 1px solid #e2e8f0;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

 