.image-container {
    position: relative;
    overflow: hidden;
 
    &:hover {
        .image {
            transform: scale(1.1);
        }
    }
}

.image {
    width: 100%;
    transition: transform 0.3s ease-out;
    min-height: 220px;
    object-fit: cover;
}

.image-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 100%;
    text-shadow: 0px 0px 2px rgb(0, 0, 0);
}

.swiper-container-wrapper {
  position: relative;
}

.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%; /* Adjust as necessary */
  z-index: 10; /* Ensure they are above the swiper slides */
}

.swiper-button-next {
  right: -30px; /* Adjust based on your design */
}

.swiper-button-prev {
  left: -30px; /* Adjust based on your design */
}

.slick-arrow.slick-disabled{
  display: none !important;
}