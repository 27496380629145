 

@media screen and (min-width: 1200px){
    .SearchWidget-bg .search-form.horizontal {
        display: inline-flex !important;
        background-color: #fff !important;
        padding-left: 15px;
        padding-right: 15px;
    }
    .search-form-wrapper{
        display: inline-block !important;
        background-color: transparent !important;
    }
}

.search-video-widget video{
    object-fit: cover;
}

.SearchWidget-bg,
.search-video-widget{
    h2,h3{
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
    }
}

.search-form-wrapper{
    position: relative;
     z-index: 11;
}