.enquire-form input {
    height: 38px;
    font-size: 16px;
}

.enquire-form .DateRangePickerInput {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.enquire-form .phone-input-container {
    width: 100%;
}

.enquire-form .phone-input-container .form-control {
    height: 38px !important;
    padding: 6px 12px !important;
    font-size: 14px;
}

.enquire-form .phone-input-container .flag-dropdown {
    height: 22px !important;
}

.enquire-form .phone-input-container .selected-flag {
    height: 38px !important;
}

.enquire-form .phone-input-container .flag-dropdown .country-list {
    max-height: 200px;
    overflow-y: auto;
}