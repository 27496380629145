.masonry-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; // Added to contain the image scale effect
  position: relative; // Added to allow absolute positioning of children
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

  img {
    transition: transform 0.3s ease; // Added to animate image scale
  }

  &:hover {
    img {
      transform: scale(1.1); // Added to scale image on hover
    }
  }
}

.grid-item-single {
  grid-row: span 1;
  grid-column: span 1;
}

.grid-item-double {
  grid-row: span 2;
  grid-column: span 2;
}

.masonry-wrapper-desc {
  position: absolute;
  bottom: 0;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}