.Filters {
 
}

.svgFilters{
    display:inline-block;
    height:14px;
    width:14px;
    fill:currentColor;
    margin-left: 10px
}