.reviews-container {
  
}

.review-card {
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
   
}

.review-card h5 {
    margin: 0 0 10px;
    color: #333;
}

.review-card p {
    margin: 0 0 10px;
}

.review-details {
    font-size: 0.9em;
    color: #666;
}

.review-details .management-reply {
    margin-top: 15px;
    padding: 10px;
    background-color: #f4f4f4;
    margin-left: 30px;
}

.review-details div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.review-details .management-reply p {
    margin: 0;
}