 .swiper-slide {
     transition: 0.5s;
 }

 
.view-all-photos {
    position: absolute;
    top: 3rem;
    z-index: 9;
    right: 3rem;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9;
    padding: 5px 12px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    background: rgba(255, 255, 255, 0.9);
}