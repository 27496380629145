.Location {
    outline: none;
}


.location input {
    border: none !important;
}

.location .searchWrapper {
    border-right: none;
    border-top: none;
    border-bottom: none;
}


.horizontal .location .searchWrapper input::placeholder {
    color: black;
}

.vertical .searchWrapper{
    padding: 0;
}

@media screen and (min-width: 1200px) {
    .horizontal .location .searchWrapper {
        border: none !important;
    }
}
 
.Location {
    outline: none;
}

.location input {
    border: none !important;
}

.location .searchWrapper input::placeholder {
    color: black;
}

@media screen and (min-width: 1200px) {
    .horizontal .location .searchWrapper {
        border: none !important;
    }
}

@media screen and (min-width: 1500px) {
    input#search_input {
        width: 290px;
    }
}

.location-buttons {
    display: flex;
    gap: 10px;
    overflow: auto;
    padding-bottom: 20px;
    white-space: nowrap;
}

.location-button {
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
 
}

.location-button.selected {
    background-color: #2e2e2e;
    color: white;
}

.hidden-checkbox {
    opacity: 0;
    position: absolute;
    left: -9999px;
}

.location-button:hover:not(.selected) {
    background-color: #e0e0e0;
}