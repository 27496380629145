 .main-app{
        min-height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
 }

.no-scroll .topBar{
    z-index: 9999 !important;
    position: relative !important;
 }

 html,
 body,
 div#root {
     width: 100%;
     height: 100%;
 }

 body{
    overflow: scroll;
 }

 .overflow-hidden-body [class^="TopBar"]{
    z-index: 1;
 }