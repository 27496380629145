 .DatePicker {
    position: relative;
    z-index: 99;
 }

.buttonGroup {
     display: flex;
     gap: 10px;
     border-bottom: 1px solid #ccc;
     padding: 10px;
     overflow: auto;
 }

  .date-picker-container-mobile .buttonGroup {
      margin-bottom: 5px;
  }

 .button {
     padding: 3px 10px;
     border: 1px solid #ccc;
     background-color: #ffffff;
     cursor: pointer;
     transition: all 0.3s ease;
     color: #ccc;
     border-radius: 5px;
     font-size: 12px;
        text-wrap: nowrap;
 }

 
 .selected {
     border-color: #000000;
     background-color: #ffffff;
     color: rgb(0, 0, 0);
 }

 .hiddenRadio {
     display: none;
 }