.grid-item-single {
    max-height: 350px;
    overflow: hidden;
}

.grid-item-double {
    max-height: 720px;
    /* 2 * 350px */
    overflow: hidden;
}

.masonary-wrapepr-descr p {
    margin: 0;
}

.masonary-image {
    transition: all 0.3s ease-in-out;
}

.masonary-image:hover img{
    transform: scale(1.05);
}