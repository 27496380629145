    @import "custom.scss";

    .n-button {
        display: inline-block;
        padding: 0.3em 1em;
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: darken($primary, 10%);
        }
    }