.OverlayContainer {
 
}

.OverlayContainer:hover{
   z-index: 9999 !important;
}

.OverlayContainer:focus {
   z-index: 9999 !important;
}