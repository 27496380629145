.WishButton {
    background-color: white;
    padding: 5px;
    border-radius: 100%;
}

.WishButton svg{
    z-index: 2;
}

.WishButton a{
    color: primary;
}