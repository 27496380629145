 .Back {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
    font-size: 35px;
    color: #434343;

 }

 .Save {
    border-bottom: 1px solid #e0e0e0;
    padding: 15px;
    font-size: 35px;
    color: #434343;
    display: flex;
    justify-content: end;
 }

 @keyframes slideInFromRight {
    from {
       transform: translateY(100%);

    }

    to {
       transform: translateY(0);

    }
 }

 .slideInRightAnimation {
    animation: slideInFromRight 0.2s ease-out;
 }