 .sliderControls {
     position: absolute;
 }

 .sliderRight,
 .sliderLeft {
     display: block;
     fill: none;
     height: 12px;
     width: 12px;
     stroke: currentcolor;
     stroke-width: 4;
     overflow: visible;
 }

 .search-slider-arrows {
     position: absolute;
 }

 .sliderBack {
     left: 15px;
 }

 .sliderNext {
     right: 15px;
 }

 .sliderControls {
     top: calc(50% - 15px);
     background: #fff;
     width: 30px;
     height: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 100%;
 }

 .sliderControls:disabled {
     display: none;
 }

 .propertyItem .sliderControls {
    opacity: 0;
    transition: opacity 0.2s linear;
 }

 
  .propertyItem:hover .sliderControls {
    opacity: 1;
  }


.swiper-button-next,
.swiper-button-prev{
    color: #fff !important;
   
}

.swiper-button-next:after,
.swiper-button-prev:after{
    font-size: 30px !important;
}

.swiper-pagination-bullet-active{
 
     background-color: #fff !important;
}

.swiper {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}