@import "custom.scss";

.card {
    // other styles...

    .image-link {
        // style for the image link...
    }

    .feature-container {
        // style for the feature container...
    }

    .price {
        // style for the price...
    }

    .notes {
        // style for the notes...
    }

    .n-button {
        display: inline-block;
        margin-top: 1em;
        padding: 0.3tw-text-lg tw-font-semibold tw-text-black tw-mt-2em 1em;
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darken($primary, 10%);
        }
    }
}