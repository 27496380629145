 
.plusSVG,.minusSVG{
    display: block;
    fill: none;
    height: 12px;
    width: 12px;
    stroke: currentcolor;
    stroke-width: 5.33333;
    overflow: visible;
}

 