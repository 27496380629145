 

.TestimonialsWidget {
    .swiper-pagination-bullet {
        width: 18px;
        height: 18px;
        border: 1px solid #7c7c7c;
        transform: scale(1) !important;
        background-color: #fff;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active-main {
        background-color: #d3d3d3 !important;
        border: none;
    }
}