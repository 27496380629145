

$input-focus-width: 0;
$primary: #00625a;
$secondary: #E0CAA9;

$headings-font-family:  "Playfair Display", serif;

// Import Bootstrap and its default variables

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
 