.scale-up {
  transition: all .3s ease-in-out;
}

.scale-up:hover {
  transform: scale(1.05);
}

.locations-gallery .image-wrapper:hover img{
  transform: scale(1.05);
}